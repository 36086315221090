import isNotAuthenticatedGuard from "../guards/is-not-authenticated.guard";
import AuthLayout from "../layouts/AuthLayout.vue";

import Login from "@/pages/Dashboard/Pages/Login.vue";
import Logout from "@/pages/Dashboard/Pages/Logout.vue";
import LoginClient from "@/components/Login.vue";
import RecoverPassword from "@/pages/Dashboard/Pages/RecoverPassword.vue";
import Register from "@/pages/Dashboard/Pages/Register.vue";
import RegisterChoose from "@/pages/Dashboard/Pages/RegisterChoose.vue";
import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";
import Stripe from "@/pages/Stripe/Stripe.vue";
import Shop from "@/pages/Shop/Shop.vue";

import AnonymousOrder from "@/pages/Orders/AnonymousOrder.vue";
import AnonymousAccess from "@/pages/Orders/AnonymousAccess.vue";
import AnonymousResult from "@/pages/Orders/AnonymousResult.vue";
import AnonymousWaitingOrder from "@/pages/Orders/AnonymousWaitingOrder.vue";
import Schedule from "@/pages/Schedule.vue";
import tickets from "@/pages/tickets.vue";
import MercadoPagoRedirect from  "@/pages/MercadoPagoRedirect.vue";

export const authPages = {
  path: "/auth",
  name: "Authentication",
  beforeEnter: isNotAuthenticatedGuard,
  redirect: { name: "Login" },
  component: AuthLayout,
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta:{title: 'Login'}
    },
    {
      path: "/logout",
      name: "Logout",
      component: Logout,
      meta:{title: 'logout'}
    },
    {
      path: "/tickets",
      name: "tickets",
      component: tickets,
      meta:{title: 'tickets'}
    },
    {
      path: "/mercadopago/oauth",
      name: "MercadoPagoRedirect",
      components: { default: MercadoPagoRedirect },
    },
    {
      path: "/loginclient",
      name: "Login",
      component: LoginClient,
      meta:{title: 'Login'}
    },
    {
      path: "/schedule",
      name: "Schedule",
      component: Schedule,
      meta:{title: 'Schedule'}
    },
    {
      path: "/login/:token",
      name: "Login",
      component: Login,
      meta:{title: 'Login'}
    },
    {
      path: "/recoverpassword/:token",
      name: "RecoverPassword",
      component: RecoverPassword,
      meta:{title: 'Password'}
    },
    {
      path: "/terms",
      name: "Terms",
      component: RtlSupport,
      meta:{title: 'Terms'}
    },
    {
      path: "/login/:login/:name",
      name: "Login",
      component: Login,
      meta:{title: 'Login'}
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta:{title: 'Register'}
    },
    {
      path: "/register/:type",
      name: "Register",
      component: Register,
      meta:{title: 'Register'}
    },
    {
      path: "/menu/:id",
      name: "AnonymousOrder",
      component: AnonymousOrder,
      meta:{title: 'Order'}
    },
    {
      path: "/shop",
      name: "Shop",
      component: Shop,
      meta:{title: 'Shop'}
    },
    {
      path: "/access/:id",
      name: "AnonymousAccess",
      component: AnonymousAccess,
      meta:{title: 'Access',qr: false}
    },
    {
      path: "/accessqr/:id",
      name: "AnonymousAccess",
      component: AnonymousAccess,
      meta:{title: 'Access',qr: true}
    },
    {
      path: "/accessresult/:orderid",
      name: "AnonymousResult",
      component: AnonymousResult,
      meta:{title: 'AccessResult'}
    },
    {
      path: "/order/:id",
      name: "AnonymousWaitingOrder",
      component: AnonymousWaitingOrder,
      meta:{title: 'Waiting'}
    },
  ],
};
