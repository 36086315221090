<template>
  <div class="card p-4">
    <div class="md-layout">
      <div class="md-layout-item md-size-100 mx-auto text-center">
        
        <h3 class="title md-primary">
          {{$t('cancelableSubscription')}}
        </h3>
          <h6 v-if="lastPayment && credits == 101" class="text-danger mt-4"> 
          Último pago registrado {{formatDate(lastPayment)}}
          </h6>

      </div>
    </div>
   <md-field slot="description"  class="md-form-group countryregister" v-if="!this.hascountry">
            <md-icon>public</md-icon>
            <country-select v-model="country" :country="country" 
              @input="changeCountry()" />
          </md-field>

    <!-- Planes de Precios -->
    <div class="md-layout mt-4">
     
      <div class="md-layout-item md-medium-size-25 md-small-size-50 md-xsmall-size-100 p-2">
        <pricing-card card-class="md-card-plain card p-2" icon-color="icon-primary">
          <h6 v-if="isGym()" slot="category" class="category text-primary">2000 {{$t('accesses')}} {{$t('perMonth')}}</h6>
          <h6 v-else slot="category" class="category text-primary">600 {{$t('orders')}} {{$t('perMonth')}}</h6>
          <md-icon slot="icon" class="md-primary">person</md-icon>
          <h3 v-if="false" slot="title" class="title text-primary">{{ price.amount }} {{ price.currency }}</h3>
          <h3 slot="title" class="title text-primary" v-if="isMercadoPagoCountry()">25.000 ARS</h3>
          <h3 slot="title" class="title text-primary" v-else>25,90 €</h3>
          <small class="text-primary" slot="title" >{{$t('perMonth')}}</small>
          
          <div slot="description" v-if="credits == 101" class="mb-4 w-100" >
            <h5>{{$t('alreadySubscribedUnlimited')}}</h5>
          </div>

       

          <MercadoPago slot="footer" v-if="isMercadoPagoCountry() && credits != 101 && false"></MercadoPago>
          
          <strong slot="footer" v-if="isMercadoPagoCountry() && credits != 101">Oferta Marzo 2025 Plan ilimitado 25.000 ARS</strong>

          <StripeButton slot="footer" v-if="!isMercadoPagoCountry()" :subscription="credits == 101 ? true : false"  plan='basic'></StripeButton>
     

        </pricing-card>
      </div>

      <div class="md-layout-item md-medium-size-25 md-small-size-50 md-xsmall-size-100 p-2">
        <pricing-card card-class="md-card-plain card p-2" icon-color="icon-primary">
          <h6 v-if="isGym()" slot="category" class="category text-primary">5000 {{$t('accesses')}} {{$t('perMonth')}}</h6>
          <h6 v-else slot="category" class="category text-primary">1500 {{$t('orders')}} {{$t('perMonth')}}</h6>
          <md-icon slot="icon" class="md-primary">storefront</md-icon>
          <h3 v-if="false" slot="title" class="title text-primary">{{ price.amount }} {{ price.currency }}</h3>
          <h3 slot="title" class="title text-primary" v-if="isMercadoPagoCountry()">45.000 ARS</h3>
          <h3 slot="title" class="title text-primary" v-else>45,90 €</h3>
          <small class="text-primary" slot="title" >{{$t('perMonth')}}</small>
         
          <div slot="description" v-if="credits == 101" class="mb-4 w-100" >
            <h5>{{$t('alreadySubscribedUnlimited')}}</h5>
          </div>

          <md-field slot="description"  class="md-form-group countryregister" v-if="!this.hascountry">
            <md-icon>public</md-icon>
          
               <country-select v-model="country" :country="country" 
              @input="changeCountry()" />
          </md-field>

          <MercadoPago slot="footer" v-if="isMercadoPagoCountry() && credits != 101 && false"></MercadoPago>
          
          <strong slot="footer" v-if="isMercadoPagoCountry() && credits != 101">Oferta Marzo 2025 Plan ilimitado <br> 25.000 ARS</strong>

          <StripeButton slot="footer" v-if="!isMercadoPagoCountry()" :subscription="credits == 101 ? true : false" plan="pro"></StripeButton>
          </pricing-card>
      </div>


      <div class="md-layout-item md-medium-size-25 md-small-size-50 md-xsmall-size-100 p-2">
        <pricing-card card-class="md-card-plain card p-2" icon-color="icon-primary">
          <h6 slot="category" class="category text-primary">{{$t('unlimitedPlan')}}</h6>
          <md-icon slot="icon" class="md-primary">business</md-icon>
          <p slot="description" class="card-description  mt-3">
           {{$t('unlimitedCredits')}}
          </p>
          <div slot="description" v-if="credits == 101" class="mb-4 w-100" >
            <h5>{{$t('alreadySubscribedUnlimited')}}</h5>
          </div>

          <h5 slot="description" v-if="false" class="title text-primary">{{$t('sendMessageChat')}} <md-icon slot="icon" class="md-primary">chat</md-icon> </h5>

          <h5 slot="description" class="title text-primary"  v-if="isMercadoPagoCountry() && credits != 101">Oferta Marzo 2025 Plan ilimitado 25.000 ARS x mes <md-icon slot="icon" class="md-primary">chat</md-icon> </h5>
          <div slot="footer" class="w-100 float-left"><MercadoPago v-if="isMercadoPagoCountry() && credits != 101"></MercadoPago></div>
      
          

          </pricing-card>
      </div>

    </div>
  </div>
</template>

<script>
import { PricingCard } from "@/components";
import { getPriceAction } from "@/pages/MercadoPago/actions";
import { editUser } from "@/pages/Auth/actions";
import MercadoPago from "@/pages/Dashboard/Components/MercadoPago.vue";
import StripeButton from "@/pages/Stripe/StripeButton.vue";
import Swal from "sweetalert2";
import { confirmSubscriptionPayment } from "@/pages/Stripe/actions"; // Importa la función de suscripción de Stripe
import { getCredits } from "@/pages/Orders/actions";
import permissionsMixin from '@/mixins/permissionsMixin';


export default {
  mixins: [permissionsMixin],
  data() {
    return {
      status:false,
      hascountry:false,
      lastPayment: false,
      credits:false,
      price: false,
      priceText: '10.000 pesos argentinos',
      selectedCountry: null,
      country:false,
      sessionId:false,
      countries: [
        { name: "United States", code: "US", currency: "USD" },
        { name: "Argentina", code: "AR", currency: "ARS" },
        { name: "Mexico", code: "MX", currency: "MXN" },
        { name: "Spain", code: "ES", currency: "EUR" },
        // Agrega más países según sea necesario
      ]
    };
  },
  created() {
    this.country = localStorage.getItem('country');
    if(this.country){
      this.hascountry = true;
    }
    this.getCreditsNow()
    if(this.$router.currentRoute.params.status){
      this.status = this.$router.currentRoute.params.status
    }
    if(this.status == 'success'){
      this.credits = 101;


         this.sessionId = this.$route.query.session_id;
        if(this.sessionId){
          confirmSubscriptionPayment(this.sessionId);
        }
        
        Swal.fire({
          title: this.$t('success'),
          text: this.$t('subscriptionActivated'),
          type: "success",
          position: 'top',
        }).then((result) => {
               this.$router.push(`/dashboard`);
                });
    }

    // Inicialmente, carga el precio en USD (predeterminado)
    this.selectedCountry = this.countries.find(c => c.currency === 'USD');
  },
  methods: {  
    formatDate(startDate) {
    const date = new Date(startDate);

          // Extraemos el año, mes y día directamente sin realizar conversiones de zonas horarias
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses son 0-11, sumamos 1
          const day = String(date.getDate()).padStart(2, '0');

          // Retornamos la fecha en formato YYYY-MM-DD
          return `${year}-${month}-${day}`;
      
    },
    changeCountry(){
        this.editCountry()
    },
    async editCountry(){
    localStorage.setItem('country',this.country);
      await editUser({country: this.country})
    },
    async getCreditsNow(){
      const { ok,credits,lastPayment } = await getCredits();
      this.credits = credits
      this.lastPayment = lastPayment
      if(this.status == 'success'){
      this.credits = 101
      }
    },
    isMercadoPagoCountry() {
      if(this.isGym()){
        return true;
      }
      const mercadoPagoCountries = [
        "AR",
        "BR",
        "CL",
        "PE",
        "CO",
        "UR",
        "MX",
        "Argentina",
        "Argentine",
        "Brasil",
        "Brazil",
        "Chile",
        "Colombia",
        "México",
        "Mexico",
        "Perú",
        "Peru",
        "Uruguay"
      ];

      // Retorna true si el país está en la lista de países de Mercado Pago
      return mercadoPagoCountries.includes(this.country);
    },
    async getPrice() {
      const response = await getPriceAction(this.selectedCountry.currency);
      this.price = response.data;
    },
    onCountryChange() {
      // Cada vez que el usuario cambie el país, se actualiza el precio
      this.getPrice();
    }
  },
  components: {
    PricingCard,
    MercadoPago,
    StripeButton
  }
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
