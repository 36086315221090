import { wencesApi } from "@/api/wencesApi";

export const registerAction = async (name, password, email, restaurant, country, role, companyLink) => {
  try {
    const { result } = await wencesApi.post("/api/users/register", {
      name,
      email,
      password,
      restaurant,
      country,
      role,
      companyLink
    });

    return {
      ok: true
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo crear el usuario",
    };
  }
};


export const confirmAction = async (token) => {
  try {
    const { result } = await wencesApi.post("/api/users/confirm-email/"+token, {
      token
    });

    return {
      ok: true
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo crear el usuario",
    };
  }
};

export const checkNameAction = async (name) => {
  try {
    const { data } = await wencesApi.post("/api/users/nameexists", {name});

    return {
      ok: true,
      exists: data.exists
    };
  } catch (error) {
    console.log(error);
    return {
      ok: false,
      message: "No se pudo crear el usuario",
    };
  }
};
