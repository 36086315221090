import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './locales/en.js';
import es from './locales/es.js';
import fr from './locales/fr.js';
import uk from './locales/uk.js';
import pt from './locales/pt.js';

Vue.use(VueI18n);

// Lista de idiomas disponibles en el sistema
const availableLanguages = ['en', 'es', 'fr', 'uk', 'pt'];

// Detectar el idioma del navegador
const browserLanguage = (navigator.language || navigator.languages[0]).split('-')[0];


// Verificar si el idioma del navegador está en la lista de idiomas disponibles
//const defaultLanguage = availableLanguages.includes(browserLanguage) ? browserLanguage : 'es';
// Obtener el idioma seleccionado del almacenamiento local o usar el idioma por defecto
//const savedLanguage = localStorage.getItem('language') || defaultLanguage;

const savedLanguage = 'es';

// Configuración de VueI18n
export const i18n = new VueI18n({
  locale: savedLanguage, // Usar el idioma detectado o guardado
  messages: {
    en: en,
    es: es,
    fr: fr,
    uk: uk,
    pt: pt
  }
});

export default i18n;