<template>
<div> 
  <div v-for="item in trainings"  >
    <div>
      <CreateTraining
        v-if="selectedTraining && selectedTraining._id == item._id"
        :key="selectedTraining._id"
        :preloadedData="selectedTraining"
        @formSubmitted="onTrainingUpdated"
        @cancel="cancel()"
      />
    </div>
    <div  class="my-1  w-100 float-left  position-inherit">
        

        <div class="w-100 px-2">
          <strong class="text-white">{{item.name}}</strong>
        <button  class="btn btn-sm btn-white p-0 m-2"  @click="editTraining(item)" 
        v-if="source == 'custom' && !isStarted && (!myPlanning || (myPlanning && myPlanning.status !== 'done'))">
             <md-icon class="text-primary">edit</md-icon><small class="text-primary">{{$t('edit')}}</small>
        </button> 
        </div>
        <MyExercises  
        v-if="!selectedTraining || selectedTraining._id != item._id" 
        :trainingId="item._id" 
        :myExercises="item.myexercises"
        :item="item"
        :source="(myPlanning && myPlanning.status == 'done') ? 'default' : source"
        :isStarted="isStarted"
        @updateTraining="onTrainingUpdated"/>           
    </div>
  </div>   
</div>
</template>

<script>
import { getAllTrainingsAction, deleteTrainingAction, getAllTrainingsOfPlanningAction } from '@/pages/Training/actions';
import CreateTraining from '@/pages/Training/CreateTraining.vue'; // El formulario de edición

import MyExercises from '@/pages/Training/MyExercises.vue'; // El formulario de edición

import Swal from 'sweetalert2';

export default {
  data() {
    return {
      trainings: [],
      currentSort: 'name',
      currentSortOrder: 'asc',
      selectedTraining: null // Entrenamiento seleccionado para editar
    };
  },
  props: {
    planningId:String,
    source: String,
    myPlanning: Object,
    isStarted: Boolean
  },
  components: {
    CreateTraining,
    MyExercises,
  },
  async created() {
    this.loadTrainings();
  },
  methods: {
    async loadTrainings() {
      try {
       let response;
        if(this.planningId){
           response = await getAllTrainingsOfPlanningAction(this.planningId); 
        }else{
        return;
        }
        if (response.ok) {
          this.trainings = response.trainings;
        } else {
          console.error('Failed to fetch trainings: ', response.message);
        }
      } catch (error) {
        console.error('Error fetching trainings: ', error);
      }
    },
    editTraining(item) {
      this.selectedTraining = item;
    },
    cancel(){
          this.selectedTraining = null;
    },
    async confirmDelete(training) {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `You are about to delete the training "${training.name}". This action cannot be undone.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel'
      });

      if (result.isConfirmed) {
        this.deleteTraining(training._id);
      }
    },
    async deleteTraining(id) {
      try {
        const response = await deleteTrainingAction(id);
        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Deleted',
            text: 'Training deleted successfully',
            timer: 2000,
            showConfirmButton: false
          });
          // Recargar la lista de entrenamientos
          this.loadTrainings();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to delete training: ' + response.message,
          });
        }
      } catch (error) {
        console.error('Error deleting training:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An unexpected error occurred',
        });
      }
    },
    onTrainingUpdated() {
      // Actualiza la lista de entrenamientos después de la edición
      this.selectedTraining = null;
      this.$emit('refresh');
     // this.loadTrainings(); // Recargar la lista
    }
  }
};

</script>

<style scoped>
.customborder{
      border: 1px solid #c4c0c0;
    border-radius: 5px;
    border-style: dashed;
    background:white;
}
</style>
