<template>
  <div class="user">
    <div class="photo">
      <img :src="avatar" alt="avatar" />

    </div>
    <div class="user-info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        @click.capture="clicked"
      >
      
        <span v-if="isDemo(false)">
          DEMO
          <b class="caret"></b>
        </span>
        <span v-else>
          {{ title }} 
          <b class="caret"></b>
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li class="w-100">
                <router-link class="md-primary" :to="{ path: '/myprofile' }" >  
                  <span class="sidebar-mini"><md-icon>person</md-icon></span>
                  <span class="sidebar-normal">{{ $t('myProfile') }}</span>
                </router-link> 
              </li>

              

              <li class="w-100">
                <a @click="logout">
                  <span class="sidebar-mini"><md-icon>logout</md-icon></span>
                  <span class="sidebar-normal">{{$t('logout')}}</span>
                </a>
              </li>

              <li class="w-100">
                <md-field class="menu-language">
                  <md-icon class="iconlanguage">language</md-icon>
                  <md-select
                    class="md-white"
                    id="language-select"
                    v-model="selectedLanguage"
                    @md-selected="changeLanguage"
                  >
                    <md-option value="en">English</md-option>
                    <md-option value="es">Español</md-option>
                    <md-option value="fr">Français</md-option>
                    <md-option value="pt">Português</md-option>
                    <md-option value="uk">Українська</md-option>
                  </md-select>
                </md-field>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  


  </div>


</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import { logoutAction,editUser } from "@/pages/Auth/actions";
import { getCredits } from "@/pages/Orders/actions";
import locales from '../../../../locales/index.js';
import { i18n } from "@/i18n";
import permissionsMixin from '@/mixins/permissionsMixin';

import { getMyProfileAction } from "@/pages/Users/actions";


export default {
  mixins: [permissionsMixin],
  components: {
    CollapseTransition
  },
  data() {
    return {
      role:false,
      selectedLanguage: "es",
      credits: 'none',
      isClosed: true,
      title: ''
    };
  },
  computed: {

    languages() {
      return this.$languages;
    },
  },
  created(){
    this.getCreditsNow()
    this.getMyProfile();

    this.role = localStorage.getItem('role');
    if(this.role == 'client'){
     // this.logout()
    }


    let allowedLanguages = Object.keys(locales);
    let defaultLanguage = navigator.language || navigator.userLanguage;
    defaultLanguage = defaultLanguage.split("-");
    defaultLanguage = defaultLanguage[0];
    const savedLanguage = localStorage.getItem("language") || defaultLanguage;
    if (!allowedLanguages.includes(savedLanguage)) {
        savedLanguage= 'en';
    }
    this.selectedLanguage = savedLanguage;
    this.$i18n.locale = savedLanguage;

  },
  props: {
    avatar: {
      type: String,
      default: process.env.BASE_URL+"img/default-avatar.png",
    },
  },
  methods: {

    async getMyProfile(){
      const data = await getMyProfileAction();

      this.title = data.name

      if(data.avatar){
        this.avatar = data.avatar
      }
    },
    async getCreditsNow(){
      const { ok,credits } = await getCredits();
      this.credits = credits
    },
    changeLanguage() {
      localStorage.setItem("language", this.selectedLanguage);
      i18n.locale = this.selectedLanguage;
      let user = {
        language : this.selectedLanguage
      }
      if(!this.isDemo(false)){
        editUser(user);
      }
    },
    clicked: function (e) {
      e.preventDefault();
    },
    toggleMenu: function () {
      this.isClosed = !this.isClosed;
    },
    async logout() {

      let urllogin = '/login';

      if(this.role == 'client'){
         urllogin = '/loginclient';
      }

      await logoutAction();
      if(this.$route.path !== urllogin && this.isDemo(false)){
        window.location.href = 'https://gotake.it';
        return;
      }
      if(this.$route.path !== urllogin){
        this.$router.push(urllogin);
      }
    },
  },
};
</script>
<style>
.iconlanguage{
      margin-right: 17px !important;
    margin-left: 12px !important;
    color: white !important;
    margin-top: 6px !important;

}

.collapsed {
  transition: opacity 1s;
}
.md-field.md-theme-default > .md-icon:after {
      background-color:  inherit !important;
}

.sidebar .credits .md-icon.md-theme-default.md-icon-font,
.credits i{
    font-size: 10px !important;
    color: #11b5c9 !important;
}

.credits{
    float: left;
    margin-left: 18px;
    color: white;
    font-size: 11px;
    margin-top: -5px;
    color: #11b5c9 !important;     overflow: hidden;
    height: 19px;
}
</style>
