<template>
  <div class="md-layout">
     <div class="md-layout-item md-size-50 md-small-size-100 w-100" v-if="!showedit">
          <md-button  class="md-primary md-sm" @click="newStab()">{{$t('addNewStablishment')}}</md-button>
      </div>
      <CreateStablishment  @save="save" @cancel="cancel" v-if="showedit" :stablishment="editing"/>

      <md-card v-if="!showedit">
        <md-card-header class="md-card-header-icon md-card-header-rose">
          <div class="card-icon">
            <md-icon>store</md-icon>
          </div>
          <h4 class="title">{{ $t('stablishments') }}</h4>
        </md-card-header>
        <md-card-content class="mt-4">
      <div v-for="item in stablishments" :key="item.id"
       class="mt-4 md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33 float-left m-2">
              
      <product-card header-animation="false">
              <div  :id="'qr-container-' + item.qr" slot="imageHeader"  class="qrcode m-1 card"> 

                <router-link class="md-primary" :to="{ path: 'accessqr/'+item.qr }">  
                 <qrcode-vue :value="'https://'+subdomain+'/accessqr/'+item.qr" :size="100"></qrcode-vue>   
                </router-link> 
              </div>   
        <img class="img" slot="imageHeader" :src="item.coverImage ? item.coverImage : product2" />
        <md-icon slot="fixed-button">build</md-icon>
        <template slot="first-button">
          <md-icon>map</md-icon>
          <md-tooltip md-direction="bottom">{{ $t('map') }}</md-tooltip>
        </template>
        <template slot="second-button">
          <md-icon>edit</md-icon>
          <md-tooltip md-direction="bottom">{{ $t('edit') }}</md-tooltip>
        </template>
        <template slot="third-button">
          <md-icon>close</md-icon>
          <md-tooltip md-direction="bottom">{{ $t('remove') }}</md-tooltip>
        </template>

        <h4 slot="title" class="title">
         {{item.name}}
        </h4>
        <template slot="footer" v-if="isRestaurant()">
            <div class="md-layout-item md-size-100 text-center">
                 <router-link  class="text-white" :to="{ path: 'stablishments/'+item._id+'/orders' }" >

                  <md-button class="md-primary md-sm"><md-icon class="material-icons">restaurant</md-icon> 
                  {{ $t('viewOrders') }}
                  </md-button> 
                 </router-link>
                 <router-link  class="text-white" :to="{ path: 'stablishments/'+item._id+'/ordersmap' }" >
                  <md-button class="md-primary md-sm"><md-icon class="material-icons">restaurant</md-icon> 
                  {{ $t('viewOrdersMap') }}
                  </md-button> 
                 </router-link>

                 <router-link  class="text-white" :to="{ path: 'menus' }" > <md-button class="md-primary md-sm"><md-icon class="material-icons">menu_book</md-icon> 
                  {{ $t('menus') }} </md-button></router-link>
                 <router-link  class="text-white" :to="{ path: 'stablishments/'+item._id+'/map' }" > <md-button class="md-primary md-sm"><md-icon class="material-icons">map</md-icon> 
                  {{ $t('edit') }} {{ $t('map') }} </md-button></router-link>
                 <router-link class="text-white" :to="{ path: 'invoices/'+item._id+'' }" > <md-button class="md-primary md-sm"><md-icon class="material-icons">receipt_long</md-icon> 
                  {{ $t('ticket') }} </md-button></router-link>

                  
              <md-button class="md-simple md-success " @click="edit(item)">
                <label>{{$t('edit')}}</label>
                <md-icon>edit</md-icon>
                <md-tooltip md-direction="top">{{$t('edit')}}</md-tooltip>
              </md-button>   


              <md-button v-if="item._id != '66843e8440848a6b8f1dcd8e'" class="md-simple md-danger " @click="deleteItem(item)">
                <label>{{$t('delete')}}</label>
                <md-icon>delete</md-icon>
                <md-tooltip md-direction="top">{{$t('delete')}}</md-tooltip>
              </md-button>   
            </div>
        </template>
        <template slot="footer" v-if="isGym()">
            <div class="md-layout-item md-size-100 text-center">
                
                
              <md-button class="md-simple md-success " @click="edit(item)">
                <label>{{$t('edit')}}</label>
                <md-icon>edit</md-icon>
                <md-tooltip md-direction="top">{{$t('edit')}}</md-tooltip>
              </md-button>
              <md-button class="md-simple md-success " @click="downloadQRCode('qr-container-' + item.qr,item.name)">
                <label>{{$t('download')}} QR</label>
                <md-icon>download</md-icon>
                <md-tooltip md-direction="top">{{$t('download')}}</md-tooltip>
              </md-button>   
            </div>
        </template>
      </product-card>
    </div>
    </md-card-content>
    </md-card>
  </div>
</template>


<script>
import QrcodeVue from 'qrcode-vue';

import permissionsMixin from '@/mixins/permissionsMixin';
import Swal from 'sweetalert2';

import {
  ProductCard,
  CreateStablishment
} from "@/components";

import { getAllAction,deleteAction } from "@/pages/Stablishments/actions";


export default {

  mixins: [permissionsMixin],
  components: {
    ProductCard,
    CreateStablishment,
    QrcodeVue
  },
  created() {
    this.getAll();

    this.subdomain = window.location.hostname;
  },
  data() {
    return {
      editing: false,
      showedit:false,
      stablishments:[],
      subdomain: false,
      product1: "/img/card-2.jpg",
      product2: "/img/card-3.jpg",
      product3: "/img/card-1.jpg"
    };
  },
  methods:{ 
        downloadQRCode(itemId,name) {
        // Encuentra el contenedor del código QR usando el ID dinámico
        const qrContainer = document.querySelector(`#${itemId} img`);

        if (!qrContainer) {
          console.error("No se encontró el elemento de imagen para el QR:", itemId);
          return;
        }

        // Obtiene el src de la imagen en base64 y crea un enlace de descarga
        const base64 = qrContainer.src;
        const link = document.createElement("a");
        link.href = base64;
        link.download = `qr_${name}.png`;
        link.click();
      },
    async deleteItem(item) {
      if(this.isDemo()){
        return;
      }
      Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('youWontBeAbleToRevertThis'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yesDeleteIt'),
        cancelButtonText: this.$t('cancel')
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await deleteAction(item._id);
            Swal.fire(
              this.$t('deleted'),
              this.$t('success')
            );
            this.getAll(); // Actualiza la lista después de eliminar
          } catch (error) {
            Swal.fire(
              this.$t('error'),
              this.$t('somethingWentWrong'),
              'error'
            );
          }
        }
      });
    },
    newStab(){
        this.editing = false
        this.showedit = true
    },
    edit(item){
        this.editing = item
        this.showedit = true
    },
    save(item){
        this.editing = null
        this.showedit = false
        this.stablishments = []
        this.getAll()
    },
    cancel(item){
        this.editing = null
        this.showedit = false
    },
    async getAll() {
      try {
        const response = await getAllAction();
        this.stablishments = response.stablishments;
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  }
};
</script>
<style scoped>
.qrcode{   
 position: absolute;
    width: 100px;
    top: 0;
    left: 0;
}
</style>
<style>
.float-left{float:left};
</style>